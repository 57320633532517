import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [21,[2]],
		"/(customer)/autopay": [96,[17]],
		"/(app)/clients": [22,[2]],
		"/(app)/clients/new": [42,[2]],
		"/(app)/clients/[id=integer]": [23,[2,3]],
		"/(app)/clients/[id=integer]/datasources": [24,[2,3]],
		"/(app)/clients/[id=integer]/datasources/edit/[datasourceid=integer]": [25,[2,3]],
		"/(app)/clients/[id=integer]/datasources/new": [26,[2,3]],
		"/(app)/clients/[id=integer]/fields": [27,[2,3]],
		"/(app)/clients/[id=integer]/lenderfields": [28,[2,3]],
		"/(app)/clients/[id=integer]/lenders": [29,[2,3]],
		"/(app)/clients/[id=integer]/lenders/new": [32,[2,3]],
		"/(app)/clients/[id=integer]/lenders/[lenderId=integer]": [30,[2,3,4]],
		"/(app)/clients/[id=integer]/lenders/[lenderId=integer]/balance": [31,[2,3,4]],
		"/(app)/clients/[id=integer]/logs": [33,[2,3]],
		"/(app)/clients/[id=integer]/products": [34,[2,3]],
		"/(app)/clients/[id=integer]/products/new": [35,[2,3]],
		"/(app)/clients/[id=integer]/status": [36,[2,3]],
		"/(app)/clients/[id=integer]/status/edit/[[statusCode]]": [37,[2,3,5]],
		"/(app)/clients/[id=integer]/status/new": [38,[2,3]],
		"/(app)/clients/[id=integer]/users": [39,[2,3,6]],
		"/(app)/clients/[id=integer]/users/edit/[[userid=integer]]": [41,[2,3,6]],
		"/(app)/clients/[id=integer]/users/[userid=integer]": [40,[2,3,6]],
		"/(auth)/contact": [90,[16]],
		"/(auth)/forgotpassword": [91,[16]],
		"/(auth)/login": [92,[16]],
		"/(auth)/logout": [93,[16]],
		"/notices": [105,[20]],
		"/(app)/obligations": [43,[2]],
		"/(app)/obligations/[id=integer]": [44,[2,7]],
		"/(app)/obligations/[id=integer]/activities": [45,[2,7]],
		"/(app)/obligations/[id=integer]/communication": [46,[2,7]],
		"/(app)/obligations/[id=integer]/contacts": [47,[2,7]],
		"/(app)/obligations/[id=integer]/contacts/add": [48,[2,7]],
		"/(app)/obligations/[id=integer]/customerPortal": [49,[2,7]],
		"/(app)/obligations/[id=integer]/data": [50,[2,7]],
		"/(app)/obligations/[id=integer]/details": [51,[2,7]],
		"/(app)/obligations/[id=integer]/ledgers/[ledger]": [52,[2,7]],
		"/(app)/obligations/[id=integer]/payments": [53,[2,7]],
		"/(app)/obligations/[id=integer]/payments/adjustment": [54,[2,7]],
		"/(app)/obligations/[id=integer]/payments/autopays": [55,[2,7]],
		"/(app)/obligations/[id=integer]/payments/autopays/new": [57,[2,7,9]],
		"/(app)/obligations/[id=integer]/payments/autopays/[autopayId=integer]": [56,[2,7,8]],
		"/(app)/obligations/[id=integer]/payments/methods": [58,[2,7]],
		"/(app)/obligations/[id=integer]/payments/payment": [59,[2,7,10]],
		"/(app)/obligations/[id=integer]/statements": [60,[2,7]],
		"/(customer)/payments": [98,[17,18]],
		"/(customer)/payments/methods": [99,[17,18]],
		"/(customer)/payments/methods/bank-account": [100,[17,18]],
		"/(customer)/payments/methods/credit-card": [101,[17,18]],
		"/(customer)/payment": [97,[17]],
		"/(open)/privacypolicy": [103,[19]],
		"/(app)/products/[id=integer]": [61,[2,11]],
		"/(app)/products/[id=integer]/dataexports": [62,[2,11]],
		"/(app)/products/[id=integer]/dataexports/[[exportid=integer]]/edit": [63,[2,11]],
		"/(app)/products/[id=integer]/dataexports/[[exportid=integer]]/history": [64,[2,11]],
		"/(app)/products/[id=integer]/dataimports": [65,[2,11,12]],
		"/(app)/products/[id=integer]/dataimports/[[importId=integer]]/edit": [66,[2,11,12]],
		"/(app)/products/[id=integer]/dataimports/[importId=integer]/history": [67,[2,11,12,13]],
		"/(app)/products/[id=integer]/dataimports/[importId=integer]/history/[historyId=integer]/records": [68,[2,11,12,13]],
		"/(app)/products/[id=integer]/documents": [69,[2,11]],
		"/(app)/products/[id=integer]/documents/edit/[[documenttemplateid=integer]]": [70,[2,11]],
		"/(app)/products/[id=integer]/fees": [71,[2,11]],
		"/(app)/products/[id=integer]/fields": [72,[2,11]],
		"/(app)/products/[id=integer]/ledgers": [73,[2,11]],
		"/(app)/products/[id=integer]/ledgers/edit/[[ledgerCode]]": [74,[2,11]],
		"/(app)/products/[id=integer]/obligationfields": [75,[2,11]],
		"/(app)/products/[id=integer]/returnreasons": [76,[2,11]],
		"/(app)/products/[id=integer]/scripts": [77,[2,11]],
		"/(app)/products/[id=integer]/scripts/[[scriptid=integer]]/edit": [78,[2,11]],
		"/(app)/products/[id=integer]/scripts/[scriptid=integer]": [79,[2,11,14]],
		"/(app)/products/[id=integer]/segments": [80,[2,11]],
		"/(app)/products/[id=integer]/update": [81,[2,11]],
		"/(app)/refresh": [82,[2]],
		"/(auth)/register": [94,[16]],
		"/(app)/reports": [83,[2]],
		"/(auth)/resetpassword": [95,[16]],
		"/(app)/statements": [84,[2]],
		"/(app)/statements/[statementid=integer]": [85,[2,15]],
		"/(app)/tasks": [86,[2]],
		"/(app)/tasks/[id=integer]": [87,[2]],
		"/(open)/terms": [104,[19]],
		"/(app)/user/activities": [88,[2]],
		"/(app)/user/edit": [89,[2]],
		"/(customer)/view-statements": [102,[17]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';